import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Feature.module.scss";

const featureData = [
  {
    icon: require("@images/features/3303.png"),
    feature: "Comprehensive Solutions",
    description:
      "We offer end-to-end digital solutions, from initial consultation and design to development, deployment, and maintenance. Your project is handled with expertise every step of the way.",
  },
  {
    icon: require("@images/features/3304.png"),
    feature: "Tailored to Your Needs",
    description:
      "Our approach is client-centric. We take the time to understand your unique business challenges and goals, delivering customized solutions that drive success.",
  },
  {
    icon: require("@images/features/3305.png"),
    feature: "Expert Team",
    description:
      "Our team consists of experienced professionals in development, design, and cybersecurity. We are committed to delivering high-quality, innovative, and reliable technology solutions.",
  },
  {
    icon: require("@images/features/3306.png"),
    feature: "Focus on Security",
    description:
      "We prioritize the security of your digital assets. Our proactive security measures protect your data and applications from potential threats, ensuring peace of mind",
  },
];

const Features = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.featureSection}>
      <div className={styles.content}>
      <h1>{t('features.title')}</h1>
        <div className={styles.featureItemsContainer}>
          {featureData.map((item, index) => (
            <div className={styles.featureItem} key={index}>
              <img src={item.icon} style={{height:'60px', marginRight:'20px'}}/>
              <div>
                <h3>{item.feature}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
