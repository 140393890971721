import React from "react";
import Hero from "@components/home/Hero/Hero";
import Organizations from "@components/home/Organizations/Organizations";
import Services from "@components/home/Services/Services";
import Count from "@components/home/Counts/Count";
import Features from "@components/home/Features/Features";
import Technologies from "@components/home/Technologies/Technologies";
import Works from "@components/home/Works/Work";
import About from "@components/home/About/About";
import Contact from "@components/home/Contact/Contact";
import Testimonials from "@components/home/Testimonials/Testimonials";
import Message from "@components/home/Message/Message";
import Team from "@components/home/Team/Team";
const Home = () => {
  return (
    <>
      <Hero />
      <Organizations />
      <Services />
      <Count />
      <Features />
      <Technologies />
      {/* <Works /> */}
      <About />
      <Message />
      {/* <Team /> */}
      {/* <Contact /> */}
      <Testimonials />
    </>
  );
};
export default Home;
