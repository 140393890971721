import axios from 'axios';

const verifyClient = async () => {
  try {
    const origin = window.location.origin; // Dynamically get the origin
    const referer = window.location.href; // Dynamically get the referer

    const response = await axios.get(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}/verify`, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Referer': referer, // Set the Referer header
        'Origin': origin // Set the Origin header
      } 
    });

    console.log(response);

    // Check if the response was successful and the key is present
    if (response.data.success && response.data.data.key) {
      // Set cookie - adjust the path and max-age according to your needs
      document.cookie = `_SPK=${response.data.data.key}; path=/; max-age=3600; secure; SameSite=Strict`;

      return response.data.success;
    } else {
      console.log('No key received or unsuccessful response');
      return false;
    }
  } catch (error) {
    console.error('Session verification failed:', error);
    return null;
  }
}

export default verifyClient;
