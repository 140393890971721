import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import styles from "./Organization.module.scss";
import shiva from "@images/clients/shiva.png";
import greenway from "@images/clients/greenway.png";
import kyro from "@images/clients/kyro.png";
import devinfinity from "@images/clients/devinfinity.png";
import ecoEnviro from "@images/clients/eco_enviro.png";
import ducat from "@images/clients/ducat.png";
import sukhwal from "@images/clients/sukhwal_associates.png";
import miraya from "@images/clients/miraya_villa.png";

const Organizations = () => {
  const { t } = useTranslation();
  const clientIcons = [
    devinfinity,
    ducat,
    miraya,
    shiva,
    greenway,
    sukhwal,
    ecoEnviro,
    kyro
  ];

  return (
    <section className={styles.organizationSection}>
      <div className={styles.content}>
        <h1>{t("organizations.title")}</h1>
        <p>{t("organizations.description")}</p>
        <div className={styles.slider}>
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={3}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            loop={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {clientIcons.map((icon, index) => (
              <SwiperSlide key={index}>
                <img src={icon} alt={`Client logo ${index}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Organizations;
