import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Services.module.scss";

const servicesData = [
  {
    icon: require("@images/services/customsoftware.png"),
    name: "Custom Software Development",
    description:
      "Create custom software tailored for your unique needs, including front-end, and core back-end technology.",
  },
  {
    icon: require("@images/services/qa.png"),
    name: "QA and Testing",
    description:
      "Ensure applications are secure, fault-tolerant, and highly available with our QA and Testing services.",
  },
  {
    icon: require("@images/services/mobile.png"),
    name: "Mobile App Development",
    description:
      "Build performant, scalable, and secure mobile applications for iOS and Android devices.",
  },
  {
    icon: require("@images/services/ai.png"),
    name: "AI and Data Science",
    description:
      "Use leading AI, machine learning, and data engineering technologies to unlock business value.",
  },
  {
    icon: require("@images/services/paas.png"),
    name: "Platform and Infrastructure",
    description:
      "Ensure applications are secure, fault-tolerant, and highly available with our DevOps and Security engineers.",
  },
  {
    icon: require("@images/services/ui.png"),
    name: "UX/UI Design",
    description:
      "Create beautiful, pixel-perfect, and easy-to-use designs that delight your end users.",
  },
];

const Services = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.servicesSection} id="services">
      <div className={styles.content}>
      <h1>{t('services.title')}</h1>
        <p>{t('services.description')}</p>
        <div className={styles.servicesList}>
          {servicesData.map((service, index) => (
            <div key={index} className={styles.service}>
              {(index === 2 || index === 3) && (
                <div
                  className={`${styles.circle} ${
                    index === 3 ? styles.blueGradient : ""
                  }`}
                ></div>
              )}

              <img src={` ${service.icon}`}/>
              <h3>{service.name}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
