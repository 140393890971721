import React from 'react';
import styles from './ComingSoon.module.scss';

interface ComingSoonProps {
  message?: string;
}

const ComingSoon: React.FC<ComingSoonProps> = ({ message }) => {
  return (
    <div className={styles.ComingSoonContainer}>
      <div className={styles.content}>
        <h1>Coming Soon</h1>
        <p>{message || 'We are working hard to launch this section. Stay tuned!'}</p>
      </div>
    </div>
  );
};

export default ComingSoon;

