import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./About.module.scss";
const About = () => {
  const { t } = useTranslation();
  
  return (
    <section className={styles.aboutSection} id="about">
      <div className={styles.content}>
        <h1>{t('about.title')}</h1>
        <h3>{t('about.subtitle')}</h3>
        <p>{t('about.description')}</p>
      </div>
    </section>
  );
};

export default About;
