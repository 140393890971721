import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Home from "@pages/Home";
import Header from "@components/header/Header";
import Footer from "@components/footer/Footer";
import Product from "@pages/Product";
import Work from "@pages/Work";
import Careers from "@pages/Career";
import Career2 from "@pages/Career2";
import BlogPage from "@pages/Blog";
import ContactPage from "@pages/Contact";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ComingSoon from "@components/coming_soon/ComingSoon";

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product" element={<Product />} />
            <Route path="/work" element={<Work />} />
            <Route path="/career" element={<Careers />} />
            <Route path="/career2" element={<Career2 />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/coming_soon" element={<ComingSoon message="This section will be available soon. Please check back later." />} />
          </Routes>
          <Footer />
        </Router>
    </>
  );
}

export default App;
