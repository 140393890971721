import React from "react";
import Contact from "@components/contact/Contact";
const ContactPage = () => {
  return (
    <>
      <Contact />
    </>
  );
};
export default ContactPage;
