import React, { useEffect, useState, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import client from './graphQL/index'; // Ensure this path matches where your Apollo client is configured
import i18n from './i18n/i18n';
import { I18nextProvider } from 'react-i18next';
import verifyClient from '@utils/verifyClient'; // Assuming you have RootState defined in your redux reducers
const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);

const WrappedApp = () => {
  const [sessionIsValid, setSessionIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const isValid = await verifyClient();
        console.log(isValid);
        setSessionIsValid(isValid);
      } catch (error) {
        console.error('Error verifying client:', error);
        setSessionIsValid(false); // Assume session is invalid on error
      } finally {
        setLoading(false);
      }
    };

    checkSession();
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    sessionIsValid ? (
      <Suspense fallback="...loading">
        <App />
      </Suspense>
    ) : (
      <p>Client does not exist</p> // Adjust according to your route settings
    )
  );
};

root.render(
  <ApolloProvider client={client}>
    <I18nextProvider i18n={i18n}>
      <WrappedApp />
    </I18nextProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
