import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Message.module.scss";
import bloomcode from "@images/bloomcode.png";
const Message = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.messageSection}>
      <div className={styles.image}>
        <div className={styles.circle}></div>
        <img src={bloomcode} />
      </div>
      <div className={styles.message}>
        <h1>{t("message.title")}</h1>
        <p>{t("message.description1")}</p>
        <p>{t("message.coFounder")}</p>
        {/* <ul>
          <li>
            <i className="fa-brands fa-facebook-f"></i>
          </li>
          <li>
            <i className="fa-brands fa-twitter"></i>
          </li>
          <li>
            <i className="fa-brands fa-linkedin-in"></i>
          </li>
        </ul> */}
      </div>
    </section>
  );
};

export default Message;
