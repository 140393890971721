import React from "react";
import Career from "@components/career2/Career";
import ComingSoon from "@components/coming_soon/ComingSoon";
const Career2 = () => {
  return (
    <>
      <ComingSoon message="This section will be available soon. Please check back later." />
      {/* <Career /> */}
    </>
  );
};
export default Career2;
