import React from "react";
import Products from "@components/products/Products";
import ComingSoon from "@components/coming_soon/ComingSoon";
const Product = () => {
  return (
    <>
      <ComingSoon message="This section will be available soon. Please check back later." />
      {/* <Products /> */}
    </>
  );
};
export default Product;
