import { useTranslation } from "react-i18next";
import styles from "./Technologies.module.scss";

import android from "@images/technologies/android.png";
import ios from "@images/technologies/ios.png";
import unity from "@images/technologies/unity.png";
import flutter from "@images/technologies/flutter.png";
import kotlin from "@images/technologies/kotlin.png";
import java from "@images/technologies/java.png";
import angular from "@images/technologies/angular.png";
import react from "@images/technologies/react.png";
import node from "@images/technologies/node.png";
import aws from "@images/technologies/aws.png";
import gcloud from "@images/technologies/gcloud.png";
import graphql from "@images/technologies/graphql.png";
import firebase from "@images/technologies/firebase.png";
import saas from "@images/technologies/saas.png";
import wordpress from "@images/technologies/wordpress.png";
import azure from "@images/technologies/azure.png";
import js from "@images/technologies/js.png";
import mysql from "@images/technologies/mysql.png";
import mongodb from "@images/technologies/mongodb.png";

const techIcons = [
  android,
  ios,
  unity,
  flutter,
  kotlin,
  java,
  angular,
  react,
  node,
  aws,
  gcloud,
  graphql, 
  firebase,
  saas,
  wordpress,
  azure,
  js, 
  mysql,
  mongodb
];

const Technologies = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.technologiesSection}>
      <div className={styles.content}>
        <h1>{t("technologies.title")}</h1>
        <div className={styles.techIcons}>
          {techIcons.map((icon, index) => (
            <div key={index} className={styles.techIcon}>
              <img src={icon} alt={`technology-${index}`} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Technologies;
