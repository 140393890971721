import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useCreateQueryMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useCheckEmailVerificationStatusQuery,
  OtpActions,
} from '@gql/grphql';
import styles from './Contact.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import encryptWithPublicKey from '@utils/encryption';
import { useTranslation } from 'react-i18next';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  service: string[];
  message: string;
  otp: string;
}

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const initialQueryData: FormData = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    service: [],
    message: '',
    otp: '',
  };
  const [formData, setFormData] = useState<FormData>(initialQueryData);
  const [otpSent, setOtpSent] = useState(false);
  const [createQuery, { loading: creating, error }] = useCreateQueryMutation();
  const [sendOtp, { loading: sendingOtp }] = useSendOtpMutation();
  const [verifyOtp, { loading: verifyingOtp }] = useVerifyOtpMutation();
  const { data: verificationData, loading: checkingVerification } =
    useCheckEmailVerificationStatusQuery({
      variables: { email: formData.email, method: OtpActions.CREATE_QUERY },
      skip: !formData.email,
    });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      const updatedServices = checked
        ? [...formData.service, value]
        : formData.service.filter((service) => service !== value);
      setFormData({ ...formData, service: updatedServices });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleActionButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.firstName || !formData.email || !formData.message) {
      toast.error('Please fill in all required fields.');
      return;
    }
    if (!verificationData?.checkEmailVerificationStatus?.success && !otpSent) {
      const sendResult = await sendOtp({
        variables: { email: formData.email, method: OtpActions.CREATE_QUERY },
      });
      if (sendResult.data?.sendOtp?.success) {
        toast.success('OTP sent successfully.');
        setOtpSent(true);
      } else {
        toast.error(
          sendResult.data?.sendOtp?.message || 'Failed to send OTP. Please try again.'
        );
      }
    } else if (otpSent) {
      const verifyResult = await verifyOtp({
        variables: {
          email: formData.email,
          method: OtpActions.CREATE_QUERY,
          otp: formData.otp,
        },
      });
      if (verifyResult.data?.verifyOtp?.success) {
        setOtpSent(false);
        handleSubmitQuery();
      } else {
        toast.error(
          verifyResult.data?.verifyOtp?.message ||
            'OTP verification failed. Please check the OTP and try again.'
        );
      }
    } else {
      handleSubmitQuery();
    }
  };

  const handleSubmitQuery = async () => {
    const publicKey = Cookies.get('_SPK');
    if (!publicKey) {
      toast.error('Public key not found. Cannot encrypt data.');
      return;
    }
    try {
      const encryptedFirstName = await encryptWithPublicKey(publicKey, formData.firstName);
      const encryptedLastName = await encryptWithPublicKey(publicKey, formData.lastName);
      const encryptedEmail = await encryptWithPublicKey(publicKey, formData.email);
      const encryptedMobileNumber = await encryptWithPublicKey(publicKey, formData.mobileNumber);
  
      // Creating an HTML message template
      const servicesMessage = formData.service.length > 0 
        ? `<p><strong>Selected services:</strong> ${formData.service.join(', ')}</p>` 
        : '';
      const htmlMessage = `
        <p><strong>Name:</strong> ${formData.firstName} ${formData.lastName}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Phone:</strong> ${formData.mobileNumber}</p>
        ${servicesMessage}
        <p><strong>Message:</strong> ${formData.message}</p>
      `;
      const encryptedMessage = await encryptWithPublicKey(publicKey, htmlMessage);
  
      const response = await createQuery({
        errorPolicy: 'all',
        variables: {
          input: {
            name: `${encryptedFirstName} ${encryptedLastName}`,
            email: encryptedEmail,
            phoneNo: encryptedMobileNumber,
            description: encryptedMessage,
            subject: 'Enquiry',
          },
        },
      });
  
      if (response.data && !error) {
        toast.success('Successfully submitted the enquiry.');
        setFormData(initialQueryData);
      } else {
        toast.error(`Failed to submit the enquiry: ${error?.message}`);
      }
    } catch (err) {
      console.error('Error submitting encrypted enquiry:', err);
      toast.error('Error: Submission failed. Please check the console for more details.');
    }
  };
  
  

  return (
    <section id="contact" className={styles.contactSection}>
      <div className={styles.container}>
        <div className={styles.contactContainer}>
          <div className={styles.contactDetails}>
            <h3>Contact Information</h3>
            <p>
              Fill up the form and our team will get back to you within 24 hours.
            </p>
            <div className={styles.allDetails}>
              <div className={styles.detail}>
                <FontAwesomeIcon icon={faPhone} className={styles.icon} />
                <p>+91 7737030819</p>
              </div>
              <div className={styles.detail}>
                <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
                <p>contact@bloomcodetech.com</p>
              </div>
              <div className={styles.detail}>
                <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
                <p>
                  121 First Floor, SBE, Vatika Rd, Jaipur
                  Rajasthan - 303905
                </p>
              </div>
              <div>
                <ul className={styles.ftSocialLinks}>
                  <li>
                    <i className="fa-brands fa-instagram"></i>
                  </li>
                  <li>
                    <i className="fa-brands fa-facebook-f"></i>
                  </li>
                  <li>
                    <i className="fa-brands fa-twitter"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.formDiv}>
            <form onSubmit={handleActionButtonClick}>
              <div className={styles.name}>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  autoFocus
                  className={styles.field}
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  className={styles.field}
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                className={styles.field}
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Mobile Number"
                className={styles.field}
                value={formData.mobileNumber}
                onChange={handleInputChange}
                required
              />
              <label>
                <b>Choose the service</b>
              </label>
              <div className={styles.checkboxGroup}>
                <label>
                  <input
                    type="checkbox"
                    id="development"
                    name="service"
                    value="Development"
                    onChange={handleInputChange}
                  />
                  Development
                </label>
                <label>
                  <input
                    type="checkbox"
                    id="digitalMarketing"
                    name="service"
                    value="Digital Marketing"
                    onChange={handleInputChange}
                  />
                  Digital Marketing
                </label>
                <label>
                  <input
                    type="checkbox"
                    id="studentConsultancy"
                    name="service"
                    value="Student Consultancy"
                    onChange={handleInputChange}
                  />
                  Student Consultancy
                </label>
                <label>
                  <input
                    type="checkbox"
                    id="brandManagement"
                    name="service"
                    value="Brand Management"
                    onChange={handleInputChange}
                  />
                  Brand Management
                </label>
              </div>
              <textarea
                name="message"
                id="message"
                placeholder="Tell us about your project"
                className={`${styles.field} ${styles.textarea}`}
                value={formData.message}
                onChange={handleInputChange}
                required
              />
              {otpSent && (
                <div className={styles.formGroup}>
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    placeholder="Enter OTP"
                    value={formData.otp}
                    className={styles.field}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}
              <button
                className={styles.button}
                type="submit"
                disabled={creating || sendingOtp || verifyingOtp}
              >
                {creating || sendingOtp || verifyingOtp ? (
                  <div className={styles.loader}></div> // Display loader when processing
                ) : (
                  'Send Message'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
