import React from "react";
import styles from "./Count.module.scss";

const countData = [
  { count: "100+", description: "Customers have trusted Bloomtech since our launch." },
  { count: "85%", description: "Satisfaction rate comes from our initial customers." },
  { count: "4.8/5.0", description: "Average customer ratings we have got all over the internet." }
];

const Count = () => {
  return (
    <section className={styles.countSection}>
      <div className={styles.content}>
        {countData.map((item, index) => (
          <div className={styles.count} key={index}>
            <h1>{item.count}</h1>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Count;
