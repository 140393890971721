import React from "react";
import Career from "@components/career/Career";
import ComingSoon from "@components/coming_soon/ComingSoon";
const Careers = () => {
  return (
    <>
        <ComingSoon message="This section will be available soon. Please check back later." />
    </>
  );
};
export default Careers;
