import React from "react";
import Works from "@components/home/Works/Work";
import ComingSoon from "@components/coming_soon/ComingSoon";
const Work = () => {
  return (
    <>
      <ComingSoon message="This section will be available soon. Please check back later." />
      {/* <Works /> */}
    </>
  );
};
export default Work;
