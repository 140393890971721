import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import { useLocation } from "react-router-dom";
const Header = () => {
  const [isScrolled, setIsScrolled] = useState(true);
  const [isNavVisible, setNavVisibility] = useState(false);
  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const isHomePage = location.pathname === "/";
  return (
    <header
      className={`${styles.header}  ${isScrolled ? styles.scrolled : ""} ${
        isHomePage ? "" : styles.other
      }`}
    >
      <div className={styles.headerContent}>
        <div className={styles.logo}>
          <Link to="/">
            <img
              src={require("@images/BloomcodeWhite.png")}
              alt="Logo"
              height="40"
            />
          </Link>
        </div>
        <div className={styles.navlink}>
          <nav
            className={`${styles.navigation} ${
              isNavVisible ? styles.show : ""
            }`}
          >
            <Link to="/" className={styles.navItem} onClick={toggleNav}>
              Home
            </Link>
            <Link to="/coming_soon" className={styles.navItem} onClick={toggleNav}>
            Services
            </Link>
            <Link to="/coming_soon" className={styles.navItem} onClick={toggleNav}>
            Our Works
            </Link>
            <Link to="/coming_soon" className={styles.navItem} onClick={toggleNav}>
            About Us
            </Link>
            <Link to="/coming_soon" className={styles.navItem} onClick={toggleNav}>
            Career
            </Link>
           
            {/* <Link to="/" className={styles.navItem} onClick={toggleNav}>
          Home
        </Link>
        <Link to="/about" className={styles.navItem} onClick={toggleNav}>
          About us
        </Link>
        <Link to="/work" className={styles.navItem} onClick={toggleNav}>
          Our Works
        </Link>
        <Link to="/product" className={styles.navItem} onClick={toggleNav}>
          Products
        </Link>
        <Link to="/contact" className={styles.navItem} onClick={toggleNav}>
          Contact Us
        </Link> */}
            {/* <Link to="/guests" className={styles.navItem} onClick={toggleNav}>
              Guest Details
            </Link> */}
          </nav>
        </div>
          <Link to="/contact" className={styles.button}>
            Contact Us Now
          </Link>
        <div className={styles.hamburger} onClick={toggleNav}>
          <i className="fas fa-bars"></i>
        </div>
      </div>
    </header>
  );
};

export default Header;
