import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from 'swiper/types';
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Testimonials.module.scss";

const testimonials = [
  {
    imageSrc: require("@images/avatars/man-1.png"),
    review:
      "I am thrilled with the website Bloomcode Technologies developed for my business. The team was professional and responsive, creating a user-friendly and visually appealing site that has significantly increased our online presence. Their timely support and maintenance have ensured everything runs smoothly, making them a reliable partner for our business.",
    name: "Rajesh Kumar, Owner of Rajesh Handicrafts",
  },
  {
    imageSrc: require("@images/avatars/woman-1.png"),
    review:
      "Bloomcode Technologies has transformed our software management processes. Their expert team streamlined our operations, improving efficiency and reducing errors. Their prompt support and maintenance services have been invaluable, allowing us to focus on our core business without worrying about technical issues. Highly recommend their services!",
    name: "Anjali Sharma, Operations Manager at Shree Manufacturing",
  },
  {
    imageSrc: require("@images/avatars/man-2.png"),
    review:
      "The security measures and quick response times provided by Bloomcode Technologies have been exceptional. They implemented robust security protocols and have always been there to address any concerns promptly. Their ongoing support and maintenance have given us peace of mind, knowing our systems are secure and well-managed.",
    name: "Vikram Mehta, IT Director at SecureFin Services",
  },
  {
    imageSrc: require("@images/avatars/woman-2.png"),
    review:
      "Bloomcode Technologies' expertise in AR/VR/AI has taken our projects to the next level. Their innovative solutions and attention to detail have impressed our clients and enhanced our offerings. Their support and maintenance ensure that our systems are always up-to-date and functioning optimally. We couldn't be happier with their services!",
    name: "Priya Reddy, Creative Director at FutureVision Studios",
  },
  {
    imageSrc: require("@images/avatars/man-3.png"),
    review:
      "Switching to cloud services with Bloomcode Technologies was a game-changer for us. They helped us optimize our costs while providing reliable and scalable cloud solutions. Their support and maintenance have been top-notch, ensuring minimal downtime and maximum efficiency. Their dedication and expertise make them a valuable partner for any business looking to enhance their IT infrastructure.",
    name: "Amit Patel, CEO of Patel Logistics",
  },
];

const Testimonials = () => {
  const { t } = useTranslation();
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  return (
    <section className={styles.testimonials}>
     <div className={styles.content}>
      <div className={styles.heading}>
        <h1>{t("reviews.title")}</h1>
      </div>
      <div className={styles.swiperButtons}>
        <div ref={navigationPrevRef} className={styles.swiperButtonPrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div ref={navigationNextRef} className={styles.swiperButtonNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        spaceBetween={30}
        slidesPerView={3}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper: SwiperClass) => {
          swiper.navigation.prevEl = navigationPrevRef.current!;
          swiper.navigation.nextEl = navigationNextRef.current!;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} className="swiper">
            <div className={styles.slide}>
              <div className={styles.testimonialContent}>
                <div className={styles.reviewImage}>
                  <img src={testimonial.imageSrc} alt="Customer" />
                </div>
                <p className={styles.review}>{testimonial.review}</p>
                <div className={styles.testimonialFooter}>
                  <div>
                    <p className={styles.name}>{testimonial.name}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
