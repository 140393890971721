import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export enum ActiveStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  INACTIVE = 'INACTIVE'
}

export enum ApiModules {
  BLOGS = 'BLOGS',
  CLIENTS = 'CLIENTS',
  PORTFOLIOS = 'PORTFOLIOS',
  QUERIES = 'QUERIES',
  SERVICES = 'SERVICES',
  USERS = 'USERS'
}

export type Client = {
  __typename?: 'Client';
  clientApiPool?: Maybe<Array<Maybe<ApiModules>>>;
  clientAuthToken?: Maybe<Scalars['String']['output']>;
  clientDbUrl?: Maybe<Scalars['String']['output']>;
  clientFeedback?: Maybe<Scalars['String']['output']>;
  clientPublicKey?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactNo?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  projectDNS?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  projectIconUrl?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  smtpHost?: Maybe<Scalars['String']['output']>;
  smtpPass?: Maybe<Scalars['String']['output']>;
  smtpPort?: Maybe<Scalars['Int']['output']>;
  smtpUser?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActiveStatus>;
  storage?: Maybe<Scalars['Boolean']['output']>;
  updateAt?: Maybe<Scalars['Date']['output']>;
};

export type ClientDetailsInput = {
  clientApiPool?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientDbUrl?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNo?: InputMaybe<Scalars['String']['input']>;
  projectDNS?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectIcon?: InputMaybe<Scalars['Upload']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  smtpHost?: InputMaybe<Scalars['String']['input']>;
  smtpPass?: InputMaybe<Scalars['String']['input']>;
  smtpPort?: InputMaybe<Scalars['Int']['input']>;
  smtpUser?: InputMaybe<Scalars['String']['input']>;
  storage?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CommentStatus {
  DELETED = 'DELETED',
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE'
}

export type CredentialsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ForgotPasswordInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type Logs = {
  __typename?: 'Logs';
  _id?: Maybe<Scalars['ID']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addActivityLog?: Maybe<Scalars['Void']['output']>;
  createPortfolio?: Maybe<ResponseType1>;
  createPortfolioCategory?: Maybe<ResponseType1>;
  createPost?: Maybe<ResponseType1>;
  createPostCategory?: Maybe<ResponseType1>;
  createPostTag?: Maybe<ResponseType1>;
  createQuery?: Maybe<ResponseType1>;
  createServiceCategory?: Maybe<ResponseType1>;
  createServiceSubCategory?: Maybe<ResponseType1>;
  createUsers?: Maybe<ResponseType1>;
  deletePortfolio?: Maybe<ResponseType3>;
  deletePortfolioCategory?: Maybe<ResponseType3>;
  deletePost?: Maybe<ResponseType3>;
  deletePostCategory?: Maybe<ResponseType3>;
  deletePostTag?: Maybe<ResponseType3>;
  deleteServiceCategory?: Maybe<ResponseType3>;
  deleteServiceSubCategory?: Maybe<ResponseType3>;
  forgotPassword?: Maybe<ResponseType3>;
  generate2FASecret?: Maybe<ResponseType3>;
  logUserViaMail?: Maybe<ResponseType1>;
  logoutUser?: Maybe<ResponseType3>;
  registerClient?: Maybe<ResponseType3>;
  resetPassword?: Maybe<ResponseType3>;
  resetPasswordLoggedUser?: Maybe<ResponseType3>;
  sendOtp?: Maybe<ResponseType3>;
  updatePortfolio?: Maybe<ResponseType1>;
  updatePost?: Maybe<ResponseType1>;
  updatePostTag?: Maybe<ResponseType1>;
  updateQuery?: Maybe<ResponseType1>;
  updateServiceSubCategory?: Maybe<ResponseType1>;
  updateUser?: Maybe<ResponseType1>;
  verify2FA?: Maybe<ResponseType1>;
  verifyOtp?: Maybe<ResponseType3>;
  verifySession?: Maybe<ResponseType1>;
};


export type MutationCreatePortfolioArgs = {
  input: PortfolioInput;
};


export type MutationCreatePortfolioCategoryArgs = {
  input: PortfolioCategoryInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationCreatePostCategoryArgs = {
  input: PostCategoryInput;
};


export type MutationCreatePostTagArgs = {
  input: PostTagInput;
};


export type MutationCreateQueryArgs = {
  input: QueriesInput;
};


export type MutationCreateServiceCategoryArgs = {
  input: ServiceCategoryInput;
};


export type MutationCreateServiceSubCategoryArgs = {
  input: ServiceSubCategoryInput;
};


export type MutationCreateUsersArgs = {
  input: UserInput;
};


export type MutationDeletePortfolioArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeletePortfolioCategoryArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeletePostArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeletePostCategoryArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeletePostTagArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeleteServiceCategoryArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeleteServiceSubCategoryArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationForgotPasswordArgs = {
  input?: InputMaybe<ForgotPasswordInput>;
};


export type MutationLogUserViaMailArgs = {
  input: CredentialsInput;
};


export type MutationRegisterClientArgs = {
  input: ClientDetailsInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordLoggedUserArgs = {
  input: ResetPasswordLoggedUserInput;
};


export type MutationSendOtpArgs = {
  email: Scalars['String']['input'];
  method: OtpActions;
};


export type MutationUpdatePortfolioArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdatePostArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdatePostTagArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateQueryArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data: QueriesStatus;
};


export type MutationUpdateServiceSubCategoryArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateUserArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationVerify2FaArgs = {
  input?: InputMaybe<Verify2FaInput>;
};


export type MutationVerifyOtpArgs = {
  email: Scalars['String']['input'];
  inputOtp: Scalars['String']['input'];
  method: OtpActions;
};

export enum OtpActions {
  CREATE_QUERY = 'CREATE_QUERY',
  CREATE_USER = 'CREATE_USER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  LOGIN = 'LOGIN'
}

export enum Permissions {
  ALL = 'ALL',
  DELETE = 'DELETE',
  READ = 'READ',
  WRITE = 'WRITE'
}

export type Portfolio = {
  __typename?: 'Portfolio';
  _id?: Maybe<Scalars['ID']['output']>;
  categoryId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  featuresList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  previewImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type PortfolioCategory = {
  __typename?: 'PortfolioCategory';
  _id?: Maybe<Scalars['ID']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PortfolioCategoryInput = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  coverPicture?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type PortfolioInput = {
  categoryId: Scalars['ID']['input'];
  featuresList: Array<InputMaybe<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  previewImage: Scalars['Upload']['input'];
  websiteUrl: Scalars['String']['input'];
};

export type Post = {
  __typename?: 'Post';
  _id?: Maybe<Scalars['ID']['output']>;
  authorId?: Maybe<Scalars['ID']['output']>;
  categoryId?: Maybe<Scalars['ID']['output']>;
  commentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  publishedAt?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<PostStatus>;
  tags?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PostCategory = {
  __typename?: 'PostCategory';
  _id?: Maybe<Scalars['ID']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PostCategoryInput = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  coverPicture?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type PostComments = {
  __typename?: 'PostComments';
  _id?: Maybe<Scalars['ID']['output']>;
  authorId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  postId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<CommentStatus>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum PostStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export type PostTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  tagName?: InputMaybe<Scalars['String']['input']>;
};

export type PostTags = {
  __typename?: 'PostTags';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  tagName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type Queries = {
  __typename?: 'Queries';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<QueriesStatus>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type QueriesInput = {
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNo: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export enum QueriesStatus {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED'
}

export type Query = {
  __typename?: 'Query';
  checkEmailVerificationStatus?: Maybe<ResponseType3>;
  getClients?: Maybe<ResponseType2>;
  getClientsDetails?: Maybe<ResponseType1>;
  getPortfolioCategories?: Maybe<ResponseType2>;
  getPortfolios?: Maybe<ResponseType2>;
  getPostCategories?: Maybe<ResponseType2>;
  getPostTags?: Maybe<ResponseType2>;
  getPosts?: Maybe<ResponseType2>;
  getQueries?: Maybe<ResponseType2>;
  getServiceCategories?: Maybe<ResponseType2>;
  getServiceSubCategories?: Maybe<ResponseType2>;
  getUsers?: Maybe<ResponseType2>;
};


export type QueryCheckEmailVerificationStatusArgs = {
  email: Scalars['String']['input'];
  method: OtpActions;
};


export type QueryGetClientsArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetClientsDetailsArgs = {
  feedbacks?: InputMaybe<Scalars['Boolean']['input']>;
  icons?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetPortfolioCategoriesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryGetPortfoliosArgs = {
  Category?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPostCategoriesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryGetPostTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPostsArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetQueriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetServiceCategoriesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryGetServiceSubCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<RoleName>;
};

export type ResetPasswordInput = {
  otp?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPasswordLoggedUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ResponseData = Client | Portfolio | PortfolioCategory | Post | PostCategory | PostComments | PostTags | Queries | ServiceCategory | ServiceSubCategory | User;

export enum RoleName {
  ADMIN = 'ADMIN',
  EXTERNAL_USER = 'EXTERNAL_USER',
  MANAGER = 'MANAGER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  TEAM_LEAD = 'TEAM_LEAD',
  TEAM_MEMBER = 'TEAM_MEMBER'
}

export type Roles = {
  __typename?: 'Roles';
  _id?: Maybe<Scalars['ID']['output']>;
  permissions?: Maybe<Array<Maybe<Permissions>>>;
  roleName?: Maybe<RoleName>;
};

export type RolesInput = {
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleName?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  _id?: Maybe<Scalars['ID']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceCategoryInput = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  coverPicture?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceSubCategory = {
  __typename?: 'ServiceSubCategory';
  _id?: Maybe<Scalars['ID']['output']>;
  categoryId?: Maybe<Scalars['ID']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  descriptionList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceSubCategoryInput = {
  categoryId: Scalars['ID']['input'];
  coverPicture: Scalars['Upload']['input'];
  descriptionList: Array<InputMaybe<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']['output']>;
  activityLogs?: Maybe<Array<Maybe<Logs>>>;
  clientId?: Maybe<Scalars['ID']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  googleId?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Logs>;
  lastName?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phoneNo?: Maybe<Scalars['String']['output']>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Roles>>>;
  status?: Maybe<ActiveStatus>;
  twoFactorEnabled?: Maybe<Scalars['Boolean']['output']>;
  twoFactorSecret?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Date']['output']>;
};

export type UserInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  coverPicture?: InputMaybe<Scalars['Upload']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNo: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['Upload']['input']>;
  roles?: InputMaybe<Array<RolesInput>>;
};

export type Verify2FaInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePostInput = {
  authorId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  commentsEnabled: Scalars['Boolean']['input'];
  content: Scalars['String']['input'];
  status: Scalars['String']['input'];
  tags: Array<InputMaybe<Scalars['ID']['input']>>;
  title: Scalars['String']['input'];
};

export type ResponseType1 = {
  __typename?: 'responseType1';
  data?: Maybe<Array<Maybe<ResponseData>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ResponseType2 = {
  __typename?: 'responseType2';
  data?: Maybe<Array<Maybe<ResponseData>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  totalCounts?: Maybe<Scalars['Int']['output']>;
};

export type ResponseType3 = {
  __typename?: 'responseType3';
  data?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type PostCategoriesFieldsFragment = { __typename?: 'PostCategory', _id?: string | null, categoryName?: string | null, coverPictureUrl?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type PostFieldsFragment = { __typename?: 'Post', _id?: string | null, authorId?: string | null, title?: string | null, content?: string | null, categoryId?: string | null, tags?: Array<string | null> | null, status?: PostStatus | null, commentsEnabled?: boolean | null, createdAt?: any | null, updatedAt?: any | null, publishedAt?: any | null };

export type PostTagsFieldsFragment = { __typename?: 'PostTags', _id?: string | null, tagName?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type CreatePostMutationVariables = Exact<{
  input: CreatePostInput;
}>;


export type CreatePostMutation = { __typename?: 'Mutation', createPost?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post', _id?: string | null, authorId?: string | null, title?: string | null, content?: string | null, categoryId?: string | null, tags?: Array<string | null> | null, status?: PostStatus | null, commentsEnabled?: boolean | null, createdAt?: any | null, updatedAt?: any | null, publishedAt?: any | null } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type CreatePostCategoryMutationVariables = Exact<{
  input: PostCategoryInput;
}>;


export type CreatePostCategoryMutation = { __typename?: 'Mutation', createPostCategory?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory', _id?: string | null, categoryName?: string | null, coverPictureUrl?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type CreatePostTagMutationVariables = Exact<{
  input: PostTagInput;
}>;


export type CreatePostTagMutation = { __typename?: 'Mutation', createPostTag?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags', _id?: string | null, tagName?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type DeletePostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePostMutation = { __typename?: 'Mutation', deletePost?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type DeletePostCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePostCategoryMutation = { __typename?: 'Mutation', deletePostCategory?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type DeletePostTagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePostTagMutation = { __typename?: 'Mutation', deletePostTag?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type UpdatePostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UpdatePostMutation = { __typename?: 'Mutation', updatePost?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post', _id?: string | null, authorId?: string | null, title?: string | null, content?: string | null, categoryId?: string | null, tags?: Array<string | null> | null, status?: PostStatus | null, commentsEnabled?: boolean | null, createdAt?: any | null, updatedAt?: any | null, publishedAt?: any | null } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type UpdatePostTagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UpdatePostTagMutation = { __typename?: 'Mutation', updatePostTag?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags', _id?: string | null, tagName?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type GetPostsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
  category: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPostsQuery = { __typename?: 'Query', getPosts?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post', _id?: string | null, authorId?: string | null, title?: string | null, content?: string | null, categoryId?: string | null, tags?: Array<string | null> | null, status?: PostStatus | null, commentsEnabled?: boolean | null, createdAt?: any | null, updatedAt?: any | null, publishedAt?: any | null } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type GetPostCategoriesQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPostCategoriesQuery = { __typename?: 'Query', getPostCategories?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory', _id?: string | null, categoryName?: string | null, coverPictureUrl?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type GetPostTagsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPostTagsQuery = { __typename?: 'Query', getPostTags?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags', _id?: string | null, tagName?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type ClientFieldsFragment = { __typename?: 'Client', contactName?: string | null, contactEmail?: string | null, contactNo?: string | null, clientAuthToken?: string | null, clientDbUrl?: string | null, clientApiPool?: Array<ApiModules | null> | null, clientFeedback?: string | null, projectName?: string | null, projectIconUrl?: string | null, projectDNS?: Array<string | null> | null, smtpHost?: string | null, smtpPort?: number | null, smtpUser?: string | null, smtpPass?: string | null, storage?: boolean | null, status?: ActiveStatus | null };

export type RegisterClientMutationVariables = Exact<{
  input: ClientDetailsInput;
}>;


export type RegisterClientMutation = { __typename?: 'Mutation', registerClient?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type GetClientsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetClientsQuery = { __typename?: 'Query', getClients?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client', contactName?: string | null, contactEmail?: string | null, contactNo?: string | null, clientAuthToken?: string | null, clientDbUrl?: string | null, clientApiPool?: Array<ApiModules | null> | null, clientFeedback?: string | null, projectName?: string | null, projectIconUrl?: string | null, projectDNS?: Array<string | null> | null, smtpHost?: string | null, smtpPort?: number | null, smtpUser?: string | null, smtpPass?: string | null, storage?: boolean | null, status?: ActiveStatus | null } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type GetClientsDetailsQueryVariables = Exact<{
  feedbacks?: InputMaybe<Scalars['Boolean']['input']>;
  icons?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetClientsDetailsQuery = { __typename?: 'Query', getClientsDetails?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client', contactName?: string | null, contactEmail?: string | null, contactNo?: string | null, clientAuthToken?: string | null, clientDbUrl?: string | null, clientApiPool?: Array<ApiModules | null> | null, clientFeedback?: string | null, projectName?: string | null, projectIconUrl?: string | null, projectDNS?: Array<string | null> | null, smtpHost?: string | null, smtpPort?: number | null, smtpUser?: string | null, smtpPass?: string | null, storage?: boolean | null, status?: ActiveStatus | null } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type SendOtpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  method: OtpActions;
}>;


export type SendOtpMutation = { __typename?: 'Mutation', sendOtp?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type VerifyOtpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  method: OtpActions;
  otp: Scalars['String']['input'];
}>;


export type VerifyOtpMutation = { __typename?: 'Mutation', verifyOtp?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type CheckEmailVerificationStatusQueryVariables = Exact<{
  email: Scalars['String']['input'];
  method: OtpActions;
}>;


export type CheckEmailVerificationStatusQuery = { __typename?: 'Query', checkEmailVerificationStatus?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type PortfolioCategoriesFieldsFragment = { __typename?: 'PortfolioCategory', _id?: string | null, categoryName?: string | null, coverPictureUrl?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type PortfolioFieldsFragment = { __typename?: 'Portfolio', _id?: string | null, name?: string | null, previewImageUrl?: string | null, websiteUrl?: string | null, featuresList?: Array<string | null> | null, categoryId: string, createdAt?: any | null, updatedAt?: any | null };

export type CreatePortfolioMutationVariables = Exact<{
  input: PortfolioInput;
}>;


export type CreatePortfolioMutation = { __typename?: 'Mutation', createPortfolio?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio', _id?: string | null, name?: string | null, previewImageUrl?: string | null, websiteUrl?: string | null, featuresList?: Array<string | null> | null, categoryId: string, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type CreatePortfolioCategoryMutationVariables = Exact<{
  input: PortfolioCategoryInput;
}>;


export type CreatePortfolioCategoryMutation = { __typename?: 'Mutation', createPortfolioCategory?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory', _id?: string | null, categoryName?: string | null, coverPictureUrl?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type DeletePortfolioMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePortfolioMutation = { __typename?: 'Mutation', deletePortfolio?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type DeletePortfolioCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePortfolioCategoryMutation = { __typename?: 'Mutation', deletePortfolioCategory?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type UpdatePortfolioMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UpdatePortfolioMutation = { __typename?: 'Mutation', updatePortfolio?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio', _id?: string | null, name?: string | null, previewImageUrl?: string | null, websiteUrl?: string | null, featuresList?: Array<string | null> | null, categoryId: string, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type GetPortfolioCategoriesQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPortfolioCategoriesQuery = { __typename?: 'Query', getPortfolioCategories?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory', _id?: string | null, categoryName?: string | null, coverPictureUrl?: string | null, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type GetPortfoliosQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  Category: Scalars['String']['input'];
}>;


export type GetPortfoliosQuery = { __typename?: 'Query', getPortfolios?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio', _id?: string | null, name?: string | null, previewImageUrl?: string | null, websiteUrl?: string | null, featuresList?: Array<string | null> | null, categoryId: string, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type QueryFieldsFragment = { __typename?: 'Queries', _id?: string | null, name?: string | null, email?: string | null, subject?: string | null, phoneNo?: string | null, description?: string | null, status?: QueriesStatus | null, createdAt?: any | null, updatedAt?: any | null };

export type CreateQueryMutationVariables = Exact<{
  input: QueriesInput;
}>;


export type CreateQueryMutation = { __typename?: 'Mutation', createQuery?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries', _id?: string | null, name?: string | null, email?: string | null, subject?: string | null, phoneNo?: string | null, description?: string | null, status?: QueriesStatus | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type UpdateQueryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: QueriesStatus;
}>;


export type UpdateQueryMutation = { __typename?: 'Mutation', updateQuery?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries', _id?: string | null, name?: string | null, email?: string | null, subject?: string | null, phoneNo?: string | null, description?: string | null, status?: QueriesStatus | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type GetQueriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetQueriesQuery = { __typename?: 'Query', getQueries?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries', _id?: string | null, name?: string | null, email?: string | null, subject?: string | null, phoneNo?: string | null, description?: string | null, status?: QueriesStatus | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type ServiceCategoriesFieldsFragment = { __typename?: 'ServiceCategory', _id?: string | null, categoryName?: string | null, description?: string | null, coverPictureUrl?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type ServiceSubCategoriesFieldsFragment = { __typename?: 'ServiceSubCategory', _id?: string | null, name?: string | null, descriptionList?: Array<string | null> | null, coverPictureUrl?: string | null, categoryId?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type CreateServiceCategoryMutationVariables = Exact<{
  input: ServiceCategoryInput;
}>;


export type CreateServiceCategoryMutation = { __typename?: 'Mutation', createServiceCategory?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory', _id?: string | null, categoryName?: string | null, description?: string | null, coverPictureUrl?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type CreateServiceSubCategoryMutationVariables = Exact<{
  input: ServiceSubCategoryInput;
}>;


export type CreateServiceSubCategoryMutation = { __typename?: 'Mutation', createServiceSubCategory?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory', _id?: string | null, name?: string | null, descriptionList?: Array<string | null> | null, coverPictureUrl?: string | null, categoryId?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'User' } | null> | null } | null };

export type DeleteServiceCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteServiceCategoryMutation = { __typename?: 'Mutation', deleteServiceCategory?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type DeleteServiceSubCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteServiceSubCategoryMutation = { __typename?: 'Mutation', deleteServiceSubCategory?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type UpdateServiceSubCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UpdateServiceSubCategoryMutation = { __typename?: 'Mutation', updateServiceSubCategory?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory', _id?: string | null, name?: string | null, descriptionList?: Array<string | null> | null, coverPictureUrl?: string | null, categoryId?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'User' } | null> | null } | null };

export type GetServiceCategoriesQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetServiceCategoriesQuery = { __typename?: 'Query', getServiceCategories?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory', _id?: string | null, categoryName?: string | null, description?: string | null, coverPictureUrl?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null> | null } | null };

export type GetServiceSubCategoriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetServiceSubCategoriesQuery = { __typename?: 'Query', getServiceSubCategories?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory', _id?: string | null, name?: string | null, descriptionList?: Array<string | null> | null, coverPictureUrl?: string | null, categoryId?: string | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'User' } | null> | null } | null };

export type UserFieldsFragment = { __typename?: 'User', _id?: string | null, firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, coverPictureUrl?: string | null, phoneNo?: string | null, status?: ActiveStatus | null, clientId?: string | null, email?: string | null, roles?: Array<{ __typename?: 'Roles', roleName?: RoleName | null, permissions?: Array<Permissions | null> | null } | null> | null };

export type CreateUsersMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUsersMutation = { __typename?: 'Mutation', createUsers?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User', _id?: string | null, firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, coverPictureUrl?: string | null, phoneNo?: string | null, status?: ActiveStatus | null, clientId?: string | null, email?: string | null, roles?: Array<{ __typename?: 'Roles', roleName?: RoleName | null, permissions?: Array<Permissions | null> | null } | null> | null } | null> | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  input?: InputMaybe<ForgotPasswordInput>;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type Generate2FaSecretMutationVariables = Exact<{ [key: string]: never; }>;


export type Generate2FaSecretMutation = { __typename?: 'Mutation', generate2FASecret?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type LogUserViaMailMutationVariables = Exact<{
  input: CredentialsInput;
}>;


export type LogUserViaMailMutation = { __typename?: 'Mutation', logUserViaMail?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User', _id?: string | null, firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, coverPictureUrl?: string | null, phoneNo?: string | null, status?: ActiveStatus | null, clientId?: string | null, email?: string | null, roles?: Array<{ __typename?: 'Roles', roleName?: RoleName | null, permissions?: Array<Permissions | null> | null } | null> | null } | null> | null } | null };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { __typename?: 'Mutation', logoutUser?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type ResetPasswordLoggedUserMutationVariables = Exact<{
  input: ResetPasswordLoggedUserInput;
}>;


export type ResetPasswordLoggedUserMutation = { __typename?: 'Mutation', resetPasswordLoggedUser?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: Array<any | null> | null } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: Scalars['JSON']['input'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User', _id?: string | null, firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, coverPictureUrl?: string | null, phoneNo?: string | null, status?: ActiveStatus | null, clientId?: string | null, email?: string | null, roles?: Array<{ __typename?: 'Roles', roleName?: RoleName | null, permissions?: Array<Permissions | null> | null } | null> | null } | null> | null } | null };

export type Verify2FaMutationVariables = Exact<{
  input?: InputMaybe<Verify2FaInput>;
}>;


export type Verify2FaMutation = { __typename?: 'Mutation', verify2FA?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User', _id?: string | null, firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, coverPictureUrl?: string | null, phoneNo?: string | null, status?: ActiveStatus | null, clientId?: string | null, email?: string | null, roles?: Array<{ __typename?: 'Roles', roleName?: RoleName | null, permissions?: Array<Permissions | null> | null } | null> | null } | null> | null } | null };

export type VerifySessionMutationVariables = Exact<{ [key: string]: never; }>;


export type VerifySessionMutation = { __typename?: 'Mutation', verifySession?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User', _id?: string | null, firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, coverPictureUrl?: string | null, phoneNo?: string | null, status?: ActiveStatus | null, clientId?: string | null, email?: string | null, roles?: Array<{ __typename?: 'Roles', roleName?: RoleName | null, permissions?: Array<Permissions | null> | null } | null> | null } | null> | null } | null };

export type GetUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<RoleName>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', getUsers?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: Array<{ __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries' } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User', _id?: string | null, firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, coverPictureUrl?: string | null, phoneNo?: string | null, status?: ActiveStatus | null, clientId?: string | null, email?: string | null, roles?: Array<{ __typename?: 'Roles', roleName?: RoleName | null, permissions?: Array<Permissions | null> | null } | null> | null } | null> | null } | null };

export const PostCategoriesFieldsFragmentDoc = gql`
    fragment PostCategoriesFields on PostCategory {
  _id
  categoryName
  coverPictureUrl
  description
  createdAt
  updatedAt
}
    `;
export const PostFieldsFragmentDoc = gql`
    fragment PostFields on Post {
  _id
  authorId
  title
  content
  categoryId
  tags
  status
  commentsEnabled
  createdAt
  updatedAt
  publishedAt
}
    `;
export const PostTagsFieldsFragmentDoc = gql`
    fragment PostTagsFields on PostTags {
  _id
  tagName
  description
  createdAt
  updatedAt
}
    `;
export const ClientFieldsFragmentDoc = gql`
    fragment ClientFields on Client {
  contactName
  contactEmail
  contactNo
  clientAuthToken
  clientDbUrl
  clientApiPool
  clientFeedback
  projectName
  projectIconUrl
  projectDNS
  smtpHost
  smtpPort
  smtpUser
  smtpPass
  storage
  status
}
    `;
export const PortfolioCategoriesFieldsFragmentDoc = gql`
    fragment PortfolioCategoriesFields on PortfolioCategory {
  _id
  categoryName
  coverPictureUrl
  description
  createdAt
  updatedAt
}
    `;
export const PortfolioFieldsFragmentDoc = gql`
    fragment PortfolioFields on Portfolio {
  _id
  name
  previewImageUrl
  websiteUrl
  featuresList
  categoryId
  createdAt
  updatedAt
}
    `;
export const QueryFieldsFragmentDoc = gql`
    fragment QueryFields on Queries {
  _id
  name
  email
  subject
  phoneNo
  description
  status
  createdAt
  updatedAt
}
    `;
export const ServiceCategoriesFieldsFragmentDoc = gql`
    fragment ServiceCategoriesFields on ServiceCategory {
  _id
  categoryName
  description
  coverPictureUrl
  createdAt
  updatedAt
}
    `;
export const ServiceSubCategoriesFieldsFragmentDoc = gql`
    fragment ServiceSubCategoriesFields on ServiceSubCategory {
  _id
  name
  descriptionList
  coverPictureUrl
  categoryId
  createdAt
  updatedAt
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  _id
  firstName
  lastName
  profilePictureUrl
  coverPictureUrl
  phoneNo
  status
  clientId
  email
  roles {
    roleName
    permissions
  }
}
    `;
export const CreatePostDocument = gql`
    mutation CreatePost($input: createPostInput!) {
  createPost(input: $input) {
    success
    message
    data {
      ...PostFields
    }
  }
}
    ${PostFieldsFragmentDoc}`;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const CreatePostCategoryDocument = gql`
    mutation CreatePostCategory($input: PostCategoryInput!) {
  createPostCategory(input: $input) {
    success
    message
    data {
      ...PostCategoriesFields
    }
  }
}
    ${PostCategoriesFieldsFragmentDoc}`;
export type CreatePostCategoryMutationFn = Apollo.MutationFunction<CreatePostCategoryMutation, CreatePostCategoryMutationVariables>;

/**
 * __useCreatePostCategoryMutation__
 *
 * To run a mutation, you first call `useCreatePostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostCategoryMutation, { data, loading, error }] = useCreatePostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostCategoryMutation, CreatePostCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostCategoryMutation, CreatePostCategoryMutationVariables>(CreatePostCategoryDocument, options);
      }
export type CreatePostCategoryMutationHookResult = ReturnType<typeof useCreatePostCategoryMutation>;
export type CreatePostCategoryMutationResult = Apollo.MutationResult<CreatePostCategoryMutation>;
export type CreatePostCategoryMutationOptions = Apollo.BaseMutationOptions<CreatePostCategoryMutation, CreatePostCategoryMutationVariables>;
export const CreatePostTagDocument = gql`
    mutation CreatePostTag($input: PostTagInput!) {
  createPostTag(input: $input) {
    success
    message
    data {
      ...PostTagsFields
    }
  }
}
    ${PostTagsFieldsFragmentDoc}`;
export type CreatePostTagMutationFn = Apollo.MutationFunction<CreatePostTagMutation, CreatePostTagMutationVariables>;

/**
 * __useCreatePostTagMutation__
 *
 * To run a mutation, you first call `useCreatePostTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostTagMutation, { data, loading, error }] = useCreatePostTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostTagMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostTagMutation, CreatePostTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostTagMutation, CreatePostTagMutationVariables>(CreatePostTagDocument, options);
      }
export type CreatePostTagMutationHookResult = ReturnType<typeof useCreatePostTagMutation>;
export type CreatePostTagMutationResult = Apollo.MutationResult<CreatePostTagMutation>;
export type CreatePostTagMutationOptions = Apollo.BaseMutationOptions<CreatePostTagMutation, CreatePostTagMutationVariables>;
export const DeletePostDocument = gql`
    mutation DeletePost($id: ID!) {
  deletePost(_id: $id) {
    success
    message
    data
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DeletePostCategoryDocument = gql`
    mutation DeletePostCategory($id: ID!) {
  deletePostCategory(_id: $id) {
    success
    message
    data
  }
}
    `;
export type DeletePostCategoryMutationFn = Apollo.MutationFunction<DeletePostCategoryMutation, DeletePostCategoryMutationVariables>;

/**
 * __useDeletePostCategoryMutation__
 *
 * To run a mutation, you first call `useDeletePostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostCategoryMutation, { data, loading, error }] = useDeletePostCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostCategoryMutation, DeletePostCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostCategoryMutation, DeletePostCategoryMutationVariables>(DeletePostCategoryDocument, options);
      }
export type DeletePostCategoryMutationHookResult = ReturnType<typeof useDeletePostCategoryMutation>;
export type DeletePostCategoryMutationResult = Apollo.MutationResult<DeletePostCategoryMutation>;
export type DeletePostCategoryMutationOptions = Apollo.BaseMutationOptions<DeletePostCategoryMutation, DeletePostCategoryMutationVariables>;
export const DeletePostTagDocument = gql`
    mutation DeletePostTag($id: ID!) {
  deletePostTag(_id: $id) {
    success
    message
    data
  }
}
    `;
export type DeletePostTagMutationFn = Apollo.MutationFunction<DeletePostTagMutation, DeletePostTagMutationVariables>;

/**
 * __useDeletePostTagMutation__
 *
 * To run a mutation, you first call `useDeletePostTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostTagMutation, { data, loading, error }] = useDeletePostTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostTagMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostTagMutation, DeletePostTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostTagMutation, DeletePostTagMutationVariables>(DeletePostTagDocument, options);
      }
export type DeletePostTagMutationHookResult = ReturnType<typeof useDeletePostTagMutation>;
export type DeletePostTagMutationResult = Apollo.MutationResult<DeletePostTagMutation>;
export type DeletePostTagMutationOptions = Apollo.BaseMutationOptions<DeletePostTagMutation, DeletePostTagMutationVariables>;
export const UpdatePostDocument = gql`
    mutation UpdatePost($id: ID!) {
  updatePost(_id: $id, data: null) {
    success
    message
    data {
      ...PostFields
    }
  }
}
    ${PostFieldsFragmentDoc}`;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const UpdatePostTagDocument = gql`
    mutation UpdatePostTag($id: ID!) {
  updatePostTag(data: null, _id: $id) {
    success
    message
    data {
      ...PostTagsFields
    }
  }
}
    ${PostTagsFieldsFragmentDoc}`;
export type UpdatePostTagMutationFn = Apollo.MutationFunction<UpdatePostTagMutation, UpdatePostTagMutationVariables>;

/**
 * __useUpdatePostTagMutation__
 *
 * To run a mutation, you first call `useUpdatePostTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostTagMutation, { data, loading, error }] = useUpdatePostTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePostTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostTagMutation, UpdatePostTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostTagMutation, UpdatePostTagMutationVariables>(UpdatePostTagDocument, options);
      }
export type UpdatePostTagMutationHookResult = ReturnType<typeof useUpdatePostTagMutation>;
export type UpdatePostTagMutationResult = Apollo.MutationResult<UpdatePostTagMutation>;
export type UpdatePostTagMutationOptions = Apollo.BaseMutationOptions<UpdatePostTagMutation, UpdatePostTagMutationVariables>;
export const GetPostsDocument = gql`
    query GetPosts($id: ID!, $tagId: ID!, $category: String!, $limit: Int, $offset: Int) {
  getPosts(
    _id: $id
    tagId: $tagId
    category: $category
    offset: $offset
    limit: $limit
  ) {
    success
    message
    totalCounts
    data {
      ...PostFields
    }
  }
}
    ${PostFieldsFragmentDoc}`;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tagId: // value for 'tagId'
 *      category: // value for 'category'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables> & ({ variables: GetPostsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export function useGetPostsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsSuspenseQueryHookResult = ReturnType<typeof useGetPostsSuspenseQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;
export const GetPostCategoriesDocument = gql`
    query GetPostCategories($limit: Int!, $offset: Int!, $keyword: String) {
  getPostCategories(limit: $limit, offset: $offset, keyword: $keyword) {
    success
    message
    totalCounts
    data {
      ...PostCategoriesFields
    }
  }
}
    ${PostCategoriesFieldsFragmentDoc}`;

/**
 * __useGetPostCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPostCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetPostCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetPostCategoriesQuery, GetPostCategoriesQueryVariables> & ({ variables: GetPostCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>(GetPostCategoriesDocument, options);
      }
export function useGetPostCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>(GetPostCategoriesDocument, options);
        }
export function useGetPostCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>(GetPostCategoriesDocument, options);
        }
export type GetPostCategoriesQueryHookResult = ReturnType<typeof useGetPostCategoriesQuery>;
export type GetPostCategoriesLazyQueryHookResult = ReturnType<typeof useGetPostCategoriesLazyQuery>;
export type GetPostCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetPostCategoriesSuspenseQuery>;
export type GetPostCategoriesQueryResult = Apollo.QueryResult<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>;
export const GetPostTagsDocument = gql`
    query GetPostTags($limit: Int, $offset: Int) {
  getPostTags(limit: $limit, offset: $offset) {
    success
    message
    totalCounts
    data {
      ...PostTagsFields
    }
  }
}
    ${PostTagsFieldsFragmentDoc}`;

/**
 * __useGetPostTagsQuery__
 *
 * To run a query within a React component, call `useGetPostTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostTagsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPostTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetPostTagsQuery, GetPostTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostTagsQuery, GetPostTagsQueryVariables>(GetPostTagsDocument, options);
      }
export function useGetPostTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostTagsQuery, GetPostTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostTagsQuery, GetPostTagsQueryVariables>(GetPostTagsDocument, options);
        }
export function useGetPostTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPostTagsQuery, GetPostTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPostTagsQuery, GetPostTagsQueryVariables>(GetPostTagsDocument, options);
        }
export type GetPostTagsQueryHookResult = ReturnType<typeof useGetPostTagsQuery>;
export type GetPostTagsLazyQueryHookResult = ReturnType<typeof useGetPostTagsLazyQuery>;
export type GetPostTagsSuspenseQueryHookResult = ReturnType<typeof useGetPostTagsSuspenseQuery>;
export type GetPostTagsQueryResult = Apollo.QueryResult<GetPostTagsQuery, GetPostTagsQueryVariables>;
export const RegisterClientDocument = gql`
    mutation RegisterClient($input: ClientDetailsInput!) {
  registerClient(input: $input) {
    success
    message
    data
  }
}
    `;
export type RegisterClientMutationFn = Apollo.MutationFunction<RegisterClientMutation, RegisterClientMutationVariables>;

/**
 * __useRegisterClientMutation__
 *
 * To run a mutation, you first call `useRegisterClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerClientMutation, { data, loading, error }] = useRegisterClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterClientMutation(baseOptions?: Apollo.MutationHookOptions<RegisterClientMutation, RegisterClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterClientMutation, RegisterClientMutationVariables>(RegisterClientDocument, options);
      }
export type RegisterClientMutationHookResult = ReturnType<typeof useRegisterClientMutation>;
export type RegisterClientMutationResult = Apollo.MutationResult<RegisterClientMutation>;
export type RegisterClientMutationOptions = Apollo.BaseMutationOptions<RegisterClientMutation, RegisterClientMutationVariables>;
export const GetClientsDocument = gql`
    query GetClients($limit: Int, $offset: Int, $id: ID, $email: String) {
  getClients(limit: $limit, offset: $offset, _id: $id, contactEmail: $email) {
    success
    message
    totalCounts
    data {
      ...ClientFields
    }
  }
}
    ${ClientFieldsFragmentDoc}`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export function useGetClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsSuspenseQueryHookResult = ReturnType<typeof useGetClientsSuspenseQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetClientsDetailsDocument = gql`
    query GetClientsDetails($feedbacks: Boolean, $icons: Boolean) {
  getClientsDetails(feedbacks: $feedbacks, icons: $icons) {
    success
    message
    data {
      ...ClientFields
    }
  }
}
    ${ClientFieldsFragmentDoc}`;

/**
 * __useGetClientsDetailsQuery__
 *
 * To run a query within a React component, call `useGetClientsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsDetailsQuery({
 *   variables: {
 *      feedbacks: // value for 'feedbacks'
 *      icons: // value for 'icons'
 *   },
 * });
 */
export function useGetClientsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsDetailsQuery, GetClientsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsDetailsQuery, GetClientsDetailsQueryVariables>(GetClientsDetailsDocument, options);
      }
export function useGetClientsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsDetailsQuery, GetClientsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsDetailsQuery, GetClientsDetailsQueryVariables>(GetClientsDetailsDocument, options);
        }
export function useGetClientsDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClientsDetailsQuery, GetClientsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClientsDetailsQuery, GetClientsDetailsQueryVariables>(GetClientsDetailsDocument, options);
        }
export type GetClientsDetailsQueryHookResult = ReturnType<typeof useGetClientsDetailsQuery>;
export type GetClientsDetailsLazyQueryHookResult = ReturnType<typeof useGetClientsDetailsLazyQuery>;
export type GetClientsDetailsSuspenseQueryHookResult = ReturnType<typeof useGetClientsDetailsSuspenseQuery>;
export type GetClientsDetailsQueryResult = Apollo.QueryResult<GetClientsDetailsQuery, GetClientsDetailsQueryVariables>;
export const SendOtpDocument = gql`
    mutation SendOtp($email: String!, $method: OtpActions!) {
  sendOtp(email: $email, method: $method) {
    success
    message
    data
  }
}
    `;
export type SendOtpMutationFn = Apollo.MutationFunction<SendOtpMutation, SendOtpMutationVariables>;

/**
 * __useSendOtpMutation__
 *
 * To run a mutation, you first call `useSendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpMutation, { data, loading, error }] = useSendOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useSendOtpMutation(baseOptions?: Apollo.MutationHookOptions<SendOtpMutation, SendOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOtpMutation, SendOtpMutationVariables>(SendOtpDocument, options);
      }
export type SendOtpMutationHookResult = ReturnType<typeof useSendOtpMutation>;
export type SendOtpMutationResult = Apollo.MutationResult<SendOtpMutation>;
export type SendOtpMutationOptions = Apollo.BaseMutationOptions<SendOtpMutation, SendOtpMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation VerifyOtp($email: String!, $method: OtpActions!, $otp: String!) {
  verifyOtp(email: $email, method: $method, inputOtp: $otp) {
    success
    message
    data
  }
}
    `;
export type VerifyOtpMutationFn = Apollo.MutationFunction<VerifyOtpMutation, VerifyOtpMutationVariables>;

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      method: // value for 'method'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const CheckEmailVerificationStatusDocument = gql`
    query CheckEmailVerificationStatus($email: String!, $method: OtpActions!) {
  checkEmailVerificationStatus(email: $email, method: $method) {
    success
    message
    data
  }
}
    `;

/**
 * __useCheckEmailVerificationStatusQuery__
 *
 * To run a query within a React component, call `useCheckEmailVerificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailVerificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailVerificationStatusQuery({
 *   variables: {
 *      email: // value for 'email'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useCheckEmailVerificationStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables> & ({ variables: CheckEmailVerificationStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>(CheckEmailVerificationStatusDocument, options);
      }
export function useCheckEmailVerificationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>(CheckEmailVerificationStatusDocument, options);
        }
export function useCheckEmailVerificationStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>(CheckEmailVerificationStatusDocument, options);
        }
export type CheckEmailVerificationStatusQueryHookResult = ReturnType<typeof useCheckEmailVerificationStatusQuery>;
export type CheckEmailVerificationStatusLazyQueryHookResult = ReturnType<typeof useCheckEmailVerificationStatusLazyQuery>;
export type CheckEmailVerificationStatusSuspenseQueryHookResult = ReturnType<typeof useCheckEmailVerificationStatusSuspenseQuery>;
export type CheckEmailVerificationStatusQueryResult = Apollo.QueryResult<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>;
export const CreatePortfolioDocument = gql`
    mutation CreatePortfolio($input: PortfolioInput!) {
  createPortfolio(input: $input) {
    success
    message
    data {
      ...PortfolioFields
    }
  }
}
    ${PortfolioFieldsFragmentDoc}`;
export type CreatePortfolioMutationFn = Apollo.MutationFunction<CreatePortfolioMutation, CreatePortfolioMutationVariables>;

/**
 * __useCreatePortfolioMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioMutation, { data, loading, error }] = useCreatePortfolioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<CreatePortfolioMutation, CreatePortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePortfolioMutation, CreatePortfolioMutationVariables>(CreatePortfolioDocument, options);
      }
export type CreatePortfolioMutationHookResult = ReturnType<typeof useCreatePortfolioMutation>;
export type CreatePortfolioMutationResult = Apollo.MutationResult<CreatePortfolioMutation>;
export type CreatePortfolioMutationOptions = Apollo.BaseMutationOptions<CreatePortfolioMutation, CreatePortfolioMutationVariables>;
export const CreatePortfolioCategoryDocument = gql`
    mutation CreatePortfolioCategory($input: PortfolioCategoryInput!) {
  createPortfolioCategory(input: $input) {
    success
    message
    data {
      ...PortfolioCategoriesFields
    }
  }
}
    ${PortfolioCategoriesFieldsFragmentDoc}`;
export type CreatePortfolioCategoryMutationFn = Apollo.MutationFunction<CreatePortfolioCategoryMutation, CreatePortfolioCategoryMutationVariables>;

/**
 * __useCreatePortfolioCategoryMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioCategoryMutation, { data, loading, error }] = useCreatePortfolioCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePortfolioCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreatePortfolioCategoryMutation, CreatePortfolioCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePortfolioCategoryMutation, CreatePortfolioCategoryMutationVariables>(CreatePortfolioCategoryDocument, options);
      }
export type CreatePortfolioCategoryMutationHookResult = ReturnType<typeof useCreatePortfolioCategoryMutation>;
export type CreatePortfolioCategoryMutationResult = Apollo.MutationResult<CreatePortfolioCategoryMutation>;
export type CreatePortfolioCategoryMutationOptions = Apollo.BaseMutationOptions<CreatePortfolioCategoryMutation, CreatePortfolioCategoryMutationVariables>;
export const DeletePortfolioDocument = gql`
    mutation DeletePortfolio($id: ID!) {
  deletePortfolio(_id: $id) {
    success
    message
    data
  }
}
    `;
export type DeletePortfolioMutationFn = Apollo.MutationFunction<DeletePortfolioMutation, DeletePortfolioMutationVariables>;

/**
 * __useDeletePortfolioMutation__
 *
 * To run a mutation, you first call `useDeletePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePortfolioMutation, { data, loading, error }] = useDeletePortfolioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<DeletePortfolioMutation, DeletePortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePortfolioMutation, DeletePortfolioMutationVariables>(DeletePortfolioDocument, options);
      }
export type DeletePortfolioMutationHookResult = ReturnType<typeof useDeletePortfolioMutation>;
export type DeletePortfolioMutationResult = Apollo.MutationResult<DeletePortfolioMutation>;
export type DeletePortfolioMutationOptions = Apollo.BaseMutationOptions<DeletePortfolioMutation, DeletePortfolioMutationVariables>;
export const DeletePortfolioCategoryDocument = gql`
    mutation DeletePortfolioCategory($id: ID!) {
  deletePortfolioCategory(_id: $id) {
    success
    message
    data
  }
}
    `;
export type DeletePortfolioCategoryMutationFn = Apollo.MutationFunction<DeletePortfolioCategoryMutation, DeletePortfolioCategoryMutationVariables>;

/**
 * __useDeletePortfolioCategoryMutation__
 *
 * To run a mutation, you first call `useDeletePortfolioCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePortfolioCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePortfolioCategoryMutation, { data, loading, error }] = useDeletePortfolioCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePortfolioCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeletePortfolioCategoryMutation, DeletePortfolioCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePortfolioCategoryMutation, DeletePortfolioCategoryMutationVariables>(DeletePortfolioCategoryDocument, options);
      }
export type DeletePortfolioCategoryMutationHookResult = ReturnType<typeof useDeletePortfolioCategoryMutation>;
export type DeletePortfolioCategoryMutationResult = Apollo.MutationResult<DeletePortfolioCategoryMutation>;
export type DeletePortfolioCategoryMutationOptions = Apollo.BaseMutationOptions<DeletePortfolioCategoryMutation, DeletePortfolioCategoryMutationVariables>;
export const UpdatePortfolioDocument = gql`
    mutation UpdatePortfolio($id: ID!) {
  updatePortfolio(_id: $id, data: null) {
    success
    message
    data {
      ...PortfolioFields
    }
  }
}
    ${PortfolioFieldsFragmentDoc}`;
export type UpdatePortfolioMutationFn = Apollo.MutationFunction<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>;

/**
 * __useUpdatePortfolioMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioMutation, { data, loading, error }] = useUpdatePortfolioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>(UpdatePortfolioDocument, options);
      }
export type UpdatePortfolioMutationHookResult = ReturnType<typeof useUpdatePortfolioMutation>;
export type UpdatePortfolioMutationResult = Apollo.MutationResult<UpdatePortfolioMutation>;
export type UpdatePortfolioMutationOptions = Apollo.BaseMutationOptions<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>;
export const GetPortfolioCategoriesDocument = gql`
    query GetPortfolioCategories($limit: Int!, $offset: Int!, $keyword: String) {
  getPortfolioCategories(limit: $limit, offset: $offset, keyword: $keyword) {
    success
    message
    totalCounts
    data {
      ...PortfolioCategoriesFields
    }
  }
}
    ${PortfolioCategoriesFieldsFragmentDoc}`;

/**
 * __useGetPortfolioCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPortfolioCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetPortfolioCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetPortfolioCategoriesQuery, GetPortfolioCategoriesQueryVariables> & ({ variables: GetPortfolioCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPortfolioCategoriesQuery, GetPortfolioCategoriesQueryVariables>(GetPortfolioCategoriesDocument, options);
      }
export function useGetPortfolioCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPortfolioCategoriesQuery, GetPortfolioCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPortfolioCategoriesQuery, GetPortfolioCategoriesQueryVariables>(GetPortfolioCategoriesDocument, options);
        }
export function useGetPortfolioCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPortfolioCategoriesQuery, GetPortfolioCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPortfolioCategoriesQuery, GetPortfolioCategoriesQueryVariables>(GetPortfolioCategoriesDocument, options);
        }
export type GetPortfolioCategoriesQueryHookResult = ReturnType<typeof useGetPortfolioCategoriesQuery>;
export type GetPortfolioCategoriesLazyQueryHookResult = ReturnType<typeof useGetPortfolioCategoriesLazyQuery>;
export type GetPortfolioCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetPortfolioCategoriesSuspenseQuery>;
export type GetPortfolioCategoriesQueryResult = Apollo.QueryResult<GetPortfolioCategoriesQuery, GetPortfolioCategoriesQueryVariables>;
export const GetPortfoliosDocument = gql`
    query GetPortfolios($limit: Int, $offset: Int, $Category: String!) {
  getPortfolios(limit: null, offset: null, Category: $Category) {
    success
    message
    totalCounts
    data {
      ...PortfolioFields
    }
  }
}
    ${PortfolioFieldsFragmentDoc}`;

/**
 * __useGetPortfoliosQuery__
 *
 * To run a query within a React component, call `useGetPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfoliosQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetPortfoliosQuery(baseOptions: Apollo.QueryHookOptions<GetPortfoliosQuery, GetPortfoliosQueryVariables> & ({ variables: GetPortfoliosQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPortfoliosQuery, GetPortfoliosQueryVariables>(GetPortfoliosDocument, options);
      }
export function useGetPortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPortfoliosQuery, GetPortfoliosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPortfoliosQuery, GetPortfoliosQueryVariables>(GetPortfoliosDocument, options);
        }
export function useGetPortfoliosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPortfoliosQuery, GetPortfoliosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPortfoliosQuery, GetPortfoliosQueryVariables>(GetPortfoliosDocument, options);
        }
export type GetPortfoliosQueryHookResult = ReturnType<typeof useGetPortfoliosQuery>;
export type GetPortfoliosLazyQueryHookResult = ReturnType<typeof useGetPortfoliosLazyQuery>;
export type GetPortfoliosSuspenseQueryHookResult = ReturnType<typeof useGetPortfoliosSuspenseQuery>;
export type GetPortfoliosQueryResult = Apollo.QueryResult<GetPortfoliosQuery, GetPortfoliosQueryVariables>;
export const CreateQueryDocument = gql`
    mutation CreateQuery($input: QueriesInput!) {
  createQuery(input: $input) {
    success
    message
    data {
      ...QueryFields
    }
  }
}
    ${QueryFieldsFragmentDoc}`;
export type CreateQueryMutationFn = Apollo.MutationFunction<CreateQueryMutation, CreateQueryMutationVariables>;

/**
 * __useCreateQueryMutation__
 *
 * To run a mutation, you first call `useCreateQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQueryMutation, { data, loading, error }] = useCreateQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQueryMutation(baseOptions?: Apollo.MutationHookOptions<CreateQueryMutation, CreateQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQueryMutation, CreateQueryMutationVariables>(CreateQueryDocument, options);
      }
export type CreateQueryMutationHookResult = ReturnType<typeof useCreateQueryMutation>;
export type CreateQueryMutationResult = Apollo.MutationResult<CreateQueryMutation>;
export type CreateQueryMutationOptions = Apollo.BaseMutationOptions<CreateQueryMutation, CreateQueryMutationVariables>;
export const UpdateQueryDocument = gql`
    mutation UpdateQuery($id: ID!, $data: QueriesStatus!) {
  updateQuery(data: $data, _id: $id) {
    success
    message
    data {
      ...QueryFields
    }
  }
}
    ${QueryFieldsFragmentDoc}`;
export type UpdateQueryMutationFn = Apollo.MutationFunction<UpdateQueryMutation, UpdateQueryMutationVariables>;

/**
 * __useUpdateQueryMutation__
 *
 * To run a mutation, you first call `useUpdateQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQueryMutation, { data, loading, error }] = useUpdateQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQueryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQueryMutation, UpdateQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQueryMutation, UpdateQueryMutationVariables>(UpdateQueryDocument, options);
      }
export type UpdateQueryMutationHookResult = ReturnType<typeof useUpdateQueryMutation>;
export type UpdateQueryMutationResult = Apollo.MutationResult<UpdateQueryMutation>;
export type UpdateQueryMutationOptions = Apollo.BaseMutationOptions<UpdateQueryMutation, UpdateQueryMutationVariables>;
export const GetQueriesDocument = gql`
    query GetQueries($limit: Int, $offset: Int) {
  getQueries(limit: $limit, offset: $offset) {
    success
    message
    totalCounts
    data {
      ...QueryFields
    }
  }
}
    ${QueryFieldsFragmentDoc}`;

/**
 * __useGetQueriesQuery__
 *
 * To run a query within a React component, call `useGetQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQueriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetQueriesQuery(baseOptions?: Apollo.QueryHookOptions<GetQueriesQuery, GetQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQueriesQuery, GetQueriesQueryVariables>(GetQueriesDocument, options);
      }
export function useGetQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQueriesQuery, GetQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQueriesQuery, GetQueriesQueryVariables>(GetQueriesDocument, options);
        }
export function useGetQueriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetQueriesQuery, GetQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQueriesQuery, GetQueriesQueryVariables>(GetQueriesDocument, options);
        }
export type GetQueriesQueryHookResult = ReturnType<typeof useGetQueriesQuery>;
export type GetQueriesLazyQueryHookResult = ReturnType<typeof useGetQueriesLazyQuery>;
export type GetQueriesSuspenseQueryHookResult = ReturnType<typeof useGetQueriesSuspenseQuery>;
export type GetQueriesQueryResult = Apollo.QueryResult<GetQueriesQuery, GetQueriesQueryVariables>;
export const CreateServiceCategoryDocument = gql`
    mutation CreateServiceCategory($input: ServiceCategoryInput!) {
  createServiceCategory(input: $input) {
    success
    message
    data {
      ...ServiceCategoriesFields
    }
  }
}
    ${ServiceCategoriesFieldsFragmentDoc}`;
export type CreateServiceCategoryMutationFn = Apollo.MutationFunction<CreateServiceCategoryMutation, CreateServiceCategoryMutationVariables>;

/**
 * __useCreateServiceCategoryMutation__
 *
 * To run a mutation, you first call `useCreateServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceCategoryMutation, { data, loading, error }] = useCreateServiceCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceCategoryMutation, CreateServiceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceCategoryMutation, CreateServiceCategoryMutationVariables>(CreateServiceCategoryDocument, options);
      }
export type CreateServiceCategoryMutationHookResult = ReturnType<typeof useCreateServiceCategoryMutation>;
export type CreateServiceCategoryMutationResult = Apollo.MutationResult<CreateServiceCategoryMutation>;
export type CreateServiceCategoryMutationOptions = Apollo.BaseMutationOptions<CreateServiceCategoryMutation, CreateServiceCategoryMutationVariables>;
export const CreateServiceSubCategoryDocument = gql`
    mutation CreateServiceSubCategory($input: ServiceSubCategoryInput!) {
  createServiceSubCategory(input: $input) {
    success
    message
    data {
      ...ServiceSubCategoriesFields
    }
  }
}
    ${ServiceSubCategoriesFieldsFragmentDoc}`;
export type CreateServiceSubCategoryMutationFn = Apollo.MutationFunction<CreateServiceSubCategoryMutation, CreateServiceSubCategoryMutationVariables>;

/**
 * __useCreateServiceSubCategoryMutation__
 *
 * To run a mutation, you first call `useCreateServiceSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceSubCategoryMutation, { data, loading, error }] = useCreateServiceSubCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceSubCategoryMutation, CreateServiceSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceSubCategoryMutation, CreateServiceSubCategoryMutationVariables>(CreateServiceSubCategoryDocument, options);
      }
export type CreateServiceSubCategoryMutationHookResult = ReturnType<typeof useCreateServiceSubCategoryMutation>;
export type CreateServiceSubCategoryMutationResult = Apollo.MutationResult<CreateServiceSubCategoryMutation>;
export type CreateServiceSubCategoryMutationOptions = Apollo.BaseMutationOptions<CreateServiceSubCategoryMutation, CreateServiceSubCategoryMutationVariables>;
export const DeleteServiceCategoryDocument = gql`
    mutation DeleteServiceCategory($id: ID!) {
  deleteServiceCategory(_id: $id) {
    success
    message
    data
  }
}
    `;
export type DeleteServiceCategoryMutationFn = Apollo.MutationFunction<DeleteServiceCategoryMutation, DeleteServiceCategoryMutationVariables>;

/**
 * __useDeleteServiceCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceCategoryMutation, { data, loading, error }] = useDeleteServiceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceCategoryMutation, DeleteServiceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceCategoryMutation, DeleteServiceCategoryMutationVariables>(DeleteServiceCategoryDocument, options);
      }
export type DeleteServiceCategoryMutationHookResult = ReturnType<typeof useDeleteServiceCategoryMutation>;
export type DeleteServiceCategoryMutationResult = Apollo.MutationResult<DeleteServiceCategoryMutation>;
export type DeleteServiceCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteServiceCategoryMutation, DeleteServiceCategoryMutationVariables>;
export const DeleteServiceSubCategoryDocument = gql`
    mutation DeleteServiceSubCategory($id: ID!) {
  deleteServiceSubCategory(_id: $id) {
    success
    message
    data
  }
}
    `;
export type DeleteServiceSubCategoryMutationFn = Apollo.MutationFunction<DeleteServiceSubCategoryMutation, DeleteServiceSubCategoryMutationVariables>;

/**
 * __useDeleteServiceSubCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteServiceSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceSubCategoryMutation, { data, loading, error }] = useDeleteServiceSubCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceSubCategoryMutation, DeleteServiceSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceSubCategoryMutation, DeleteServiceSubCategoryMutationVariables>(DeleteServiceSubCategoryDocument, options);
      }
export type DeleteServiceSubCategoryMutationHookResult = ReturnType<typeof useDeleteServiceSubCategoryMutation>;
export type DeleteServiceSubCategoryMutationResult = Apollo.MutationResult<DeleteServiceSubCategoryMutation>;
export type DeleteServiceSubCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteServiceSubCategoryMutation, DeleteServiceSubCategoryMutationVariables>;
export const UpdateServiceSubCategoryDocument = gql`
    mutation UpdateServiceSubCategory($id: ID!) {
  updateServiceSubCategory(_id: $id, data: null) {
    success
    message
    data {
      ...ServiceSubCategoriesFields
    }
  }
}
    ${ServiceSubCategoriesFieldsFragmentDoc}`;
export type UpdateServiceSubCategoryMutationFn = Apollo.MutationFunction<UpdateServiceSubCategoryMutation, UpdateServiceSubCategoryMutationVariables>;

/**
 * __useUpdateServiceSubCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateServiceSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceSubCategoryMutation, { data, loading, error }] = useUpdateServiceSubCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateServiceSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceSubCategoryMutation, UpdateServiceSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceSubCategoryMutation, UpdateServiceSubCategoryMutationVariables>(UpdateServiceSubCategoryDocument, options);
      }
export type UpdateServiceSubCategoryMutationHookResult = ReturnType<typeof useUpdateServiceSubCategoryMutation>;
export type UpdateServiceSubCategoryMutationResult = Apollo.MutationResult<UpdateServiceSubCategoryMutation>;
export type UpdateServiceSubCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateServiceSubCategoryMutation, UpdateServiceSubCategoryMutationVariables>;
export const GetServiceCategoriesDocument = gql`
    query GetServiceCategories($limit: Int!, $offset: Int!, $keyword: String) {
  getServiceCategories(limit: $limit, offset: $offset, keyword: $keyword) {
    success
    message
    totalCounts
    data {
      ...ServiceCategoriesFields
    }
  }
}
    ${ServiceCategoriesFieldsFragmentDoc}`;

/**
 * __useGetServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetServiceCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables> & ({ variables: GetServiceCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
      }
export function useGetServiceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
        }
export function useGetServiceCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
        }
export type GetServiceCategoriesQueryHookResult = ReturnType<typeof useGetServiceCategoriesQuery>;
export type GetServiceCategoriesLazyQueryHookResult = ReturnType<typeof useGetServiceCategoriesLazyQuery>;
export type GetServiceCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetServiceCategoriesSuspenseQuery>;
export type GetServiceCategoriesQueryResult = Apollo.QueryResult<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>;
export const GetServiceSubCategoriesDocument = gql`
    query GetServiceSubCategories($limit: Int, $offset: Int) {
  getServiceSubCategories(limit: $limit, offset: $offset) {
    success
    message
    totalCounts
    data {
      ...ServiceSubCategoriesFields
    }
  }
}
    ${ServiceSubCategoriesFieldsFragmentDoc}`;

/**
 * __useGetServiceSubCategoriesQuery__
 *
 * To run a query within a React component, call `useGetServiceSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceSubCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetServiceSubCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceSubCategoriesQuery, GetServiceSubCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceSubCategoriesQuery, GetServiceSubCategoriesQueryVariables>(GetServiceSubCategoriesDocument, options);
      }
export function useGetServiceSubCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceSubCategoriesQuery, GetServiceSubCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceSubCategoriesQuery, GetServiceSubCategoriesQueryVariables>(GetServiceSubCategoriesDocument, options);
        }
export function useGetServiceSubCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetServiceSubCategoriesQuery, GetServiceSubCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServiceSubCategoriesQuery, GetServiceSubCategoriesQueryVariables>(GetServiceSubCategoriesDocument, options);
        }
export type GetServiceSubCategoriesQueryHookResult = ReturnType<typeof useGetServiceSubCategoriesQuery>;
export type GetServiceSubCategoriesLazyQueryHookResult = ReturnType<typeof useGetServiceSubCategoriesLazyQuery>;
export type GetServiceSubCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetServiceSubCategoriesSuspenseQuery>;
export type GetServiceSubCategoriesQueryResult = Apollo.QueryResult<GetServiceSubCategoriesQuery, GetServiceSubCategoriesQueryVariables>;
export const CreateUsersDocument = gql`
    mutation CreateUsers($input: UserInput!) {
  createUsers(input: $input) {
    success
    message
    data {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type CreateUsersMutationFn = Apollo.MutationFunction<CreateUsersMutation, CreateUsersMutationVariables>;

/**
 * __useCreateUsersMutation__
 *
 * To run a mutation, you first call `useCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersMutation, { data, loading, error }] = useCreateUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsersMutation(baseOptions?: Apollo.MutationHookOptions<CreateUsersMutation, CreateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUsersMutation, CreateUsersMutationVariables>(CreateUsersDocument, options);
      }
export type CreateUsersMutationHookResult = ReturnType<typeof useCreateUsersMutation>;
export type CreateUsersMutationResult = Apollo.MutationResult<CreateUsersMutation>;
export type CreateUsersMutationOptions = Apollo.BaseMutationOptions<CreateUsersMutation, CreateUsersMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput) {
  forgotPassword(input: $input) {
    success
    message
    data
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const Generate2FaSecretDocument = gql`
    mutation Generate2FASecret {
  generate2FASecret {
    success
    message
    data
  }
}
    `;
export type Generate2FaSecretMutationFn = Apollo.MutationFunction<Generate2FaSecretMutation, Generate2FaSecretMutationVariables>;

/**
 * __useGenerate2FaSecretMutation__
 *
 * To run a mutation, you first call `useGenerate2FaSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerate2FaSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generate2FaSecretMutation, { data, loading, error }] = useGenerate2FaSecretMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerate2FaSecretMutation(baseOptions?: Apollo.MutationHookOptions<Generate2FaSecretMutation, Generate2FaSecretMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Generate2FaSecretMutation, Generate2FaSecretMutationVariables>(Generate2FaSecretDocument, options);
      }
export type Generate2FaSecretMutationHookResult = ReturnType<typeof useGenerate2FaSecretMutation>;
export type Generate2FaSecretMutationResult = Apollo.MutationResult<Generate2FaSecretMutation>;
export type Generate2FaSecretMutationOptions = Apollo.BaseMutationOptions<Generate2FaSecretMutation, Generate2FaSecretMutationVariables>;
export const LogUserViaMailDocument = gql`
    mutation LogUserViaMail($input: CredentialsInput!) {
  logUserViaMail(input: $input) {
    success
    message
    data {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type LogUserViaMailMutationFn = Apollo.MutationFunction<LogUserViaMailMutation, LogUserViaMailMutationVariables>;

/**
 * __useLogUserViaMailMutation__
 *
 * To run a mutation, you first call `useLogUserViaMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogUserViaMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logUserViaMailMutation, { data, loading, error }] = useLogUserViaMailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogUserViaMailMutation(baseOptions?: Apollo.MutationHookOptions<LogUserViaMailMutation, LogUserViaMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogUserViaMailMutation, LogUserViaMailMutationVariables>(LogUserViaMailDocument, options);
      }
export type LogUserViaMailMutationHookResult = ReturnType<typeof useLogUserViaMailMutation>;
export type LogUserViaMailMutationResult = Apollo.MutationResult<LogUserViaMailMutation>;
export type LogUserViaMailMutationOptions = Apollo.BaseMutationOptions<LogUserViaMailMutation, LogUserViaMailMutationVariables>;
export const LogoutUserDocument = gql`
    mutation LogoutUser {
  logoutUser {
    success
    message
    data
  }
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    success
    message
    data
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetPasswordLoggedUserDocument = gql`
    mutation ResetPasswordLoggedUser($input: ResetPasswordLoggedUserInput!) {
  resetPasswordLoggedUser(input: $input) {
    success
    message
    data
  }
}
    `;
export type ResetPasswordLoggedUserMutationFn = Apollo.MutationFunction<ResetPasswordLoggedUserMutation, ResetPasswordLoggedUserMutationVariables>;

/**
 * __useResetPasswordLoggedUserMutation__
 *
 * To run a mutation, you first call `useResetPasswordLoggedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordLoggedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordLoggedUserMutation, { data, loading, error }] = useResetPasswordLoggedUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordLoggedUserMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordLoggedUserMutation, ResetPasswordLoggedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordLoggedUserMutation, ResetPasswordLoggedUserMutationVariables>(ResetPasswordLoggedUserDocument, options);
      }
export type ResetPasswordLoggedUserMutationHookResult = ReturnType<typeof useResetPasswordLoggedUserMutation>;
export type ResetPasswordLoggedUserMutationResult = Apollo.MutationResult<ResetPasswordLoggedUserMutation>;
export type ResetPasswordLoggedUserMutationOptions = Apollo.BaseMutationOptions<ResetPasswordLoggedUserMutation, ResetPasswordLoggedUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: JSON!) {
  updateUser(_id: $id, data: $data) {
    success
    message
    data {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const Verify2FaDocument = gql`
    mutation Verify2FA($input: Verify2FAInput) {
  verify2FA(input: $input) {
    success
    message
    data {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type Verify2FaMutationFn = Apollo.MutationFunction<Verify2FaMutation, Verify2FaMutationVariables>;

/**
 * __useVerify2FaMutation__
 *
 * To run a mutation, you first call `useVerify2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerify2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verify2FaMutation, { data, loading, error }] = useVerify2FaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerify2FaMutation(baseOptions?: Apollo.MutationHookOptions<Verify2FaMutation, Verify2FaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Verify2FaMutation, Verify2FaMutationVariables>(Verify2FaDocument, options);
      }
export type Verify2FaMutationHookResult = ReturnType<typeof useVerify2FaMutation>;
export type Verify2FaMutationResult = Apollo.MutationResult<Verify2FaMutation>;
export type Verify2FaMutationOptions = Apollo.BaseMutationOptions<Verify2FaMutation, Verify2FaMutationVariables>;
export const VerifySessionDocument = gql`
    mutation VerifySession {
  verifySession {
    success
    message
    data {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type VerifySessionMutationFn = Apollo.MutationFunction<VerifySessionMutation, VerifySessionMutationVariables>;

/**
 * __useVerifySessionMutation__
 *
 * To run a mutation, you first call `useVerifySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifySessionMutation, { data, loading, error }] = useVerifySessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifySessionMutation(baseOptions?: Apollo.MutationHookOptions<VerifySessionMutation, VerifySessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifySessionMutation, VerifySessionMutationVariables>(VerifySessionDocument, options);
      }
export type VerifySessionMutationHookResult = ReturnType<typeof useVerifySessionMutation>;
export type VerifySessionMutationResult = Apollo.MutationResult<VerifySessionMutation>;
export type VerifySessionMutationOptions = Apollo.BaseMutationOptions<VerifySessionMutation, VerifySessionMutationVariables>;
export const GetUsersDocument = gql`
    query getUsers($limit: Int, $offset: Int, $roleName: RoleName, $email: String) {
  getUsers(limit: $limit, offset: $offset, roleName: $roleName, email: $email) {
    success
    message
    totalCounts
    data {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      roleName: // value for 'roleName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;