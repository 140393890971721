import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.footerSection}>
      <div className={styles.footerContainer}>
        <div className={styles.ftInfo}>
          <Link to="/">
            <img
              src={require("@images/BloomcodeWhite.png")}
              alt="Logo"
              height="40"
            />
          </Link>
          <p className={styles.ftDescription}>{t("footer.description")}</p>
          <ul className={styles.ftSocialLinks}>
            <li>
              <a href="https://www.instagram.com/bloomcodetechnologies/" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/bloomcode-technologies/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-twitter"></i>
              </a>
            </li>
          </ul>

        </div>

        <div className={styles.ftList}>
          <p className={styles.ftListTitle}>Company</p>
          <ul className={styles.ftListItems}>
            <li>
              <Link to="/work">Our Work</Link>
            </li>
            <li>
              <Link to="/career">Careers</Link>
            </li>
            <li>
              <Link to="/product">Products</Link>
            </li>
            {/* <li>
              <Link to="/career2">Career2</Link>
            </li> */}
          </ul>
        </div>

        <div className={styles.ftList}>
          <p className={styles.ftListTitle}>Product</p>
          <ul className={styles.ftListItems}>
            <li>
              <Link to="/blog">Blogs</Link>
            </li>
            {/* <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <a href="#services">Lorem ipsum</a>
            </li>
            <li>
              <a href="#services">Lorem ipsum</a>
            </li> */}
          </ul>
        </div>

        <div className={styles.ftList}>
          <p className={styles.ftListTitle}>Quick Links</p>
          <ul className={styles.ftListItems}>
            {/* <li>
              <a href="#services">Term</a>
            </li> */}
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            {/* <li>
              <a href="#services">Privacy Policy</a>
            </li> */}
            {/* <li>
              <a href="#services">Cookies</a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className={styles.ftBelow}>
        <div className={styles.ftCopyright}>
          <p>{t("footer.copyright")}</p>
        </div>
        <div className={styles.ftDetails}>
          <p>
            <i className="fa-solid fa-phone" style={{ marginRight: '8px' }}></i>
            {t("footer.phone")}
          </p>
          <p>
            <i className="fa-solid fa-envelope" style={{ marginRight: '8px' }}></i>
            {t("footer.email")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
