import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Hero.module.scss";
const Hero = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.heroSection} id="hero">
      <div className={styles.content}>
        <h2>{t('hero.welcome')}</h2>
        <h1>
          <span className={styles.gradient}>{t('hero.innovate')}</span>{" "}
          <span style={{ color: "white" }}>,</span>{" "}
          <span>{t('hero.create')}</span>
          <span style={{ color: "white" }}>,</span>{" "}
          <span className={styles.gradient}>{t('hero.elevate')}</span>
        </h1>
        <p>{t('hero.description')}</p>
        <Link to="/contact" className={styles.button}>{t('hero.buttonText')}</Link>
      </div>
    </section>
  );
};
export default Hero;
