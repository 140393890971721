import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

  i18n.on('initialized', (options: any) => {
    console.log('i18next initialized with options:', options);
  });
i18n.on('loaded', (loaded: any) => {
  console.log('i18next resources loaded', loaded);
});

i18n.on('failedLoading', (lng: any, ns: any, msg: any) => {
  console.error('i18next failed loading', lng, ns, msg);
});
i18n.on('languageChanged', (lng: any) => {
  console.log('Language changed to:', lng);
});

export default i18n;
