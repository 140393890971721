import React from "react";
import Blog from "@components/blog/Blog";
import ComingSoon from "@components/coming_soon/ComingSoon";
const BlogPage = () => {
  return (
    <>
        <ComingSoon message="This section will be available soon. Please check back later." /> 
    </>
  );
};
export default BlogPage;
