import { ApolloClient, InMemoryCache } from '@apollo/client';
import createUploadLink  from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';

// Create the upload link
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include',
});

// Create a context link to set headers dynamically
const authLink = setContext((_, { headers }) => {
  const referer = window.location.href;
  console.log('Setting Referer Header:', referer); // Log the referer to ensure it's being set
  return {
    headers: {
      ...headers,
      Referer: referer // Set the custom Referer header to the current page URL
    }
  };
});

// Combine the links
const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

export default client;
